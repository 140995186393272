import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class PlanificacionesService {

  constructor() { }

  async all() {
    return axios.get('http://api.civischile.infinitech.app/planificaciones', {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async civis() {
    return axios.get('http://api.civischile.infinitech.app/planificaciones/civis', {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async colegio(colegio: number) {
    return axios.get('http://api.civischile.infinitech.app/planificaciones/colegios/' + colegio, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async colegios(rut: string) {
    return axios.post('http://api.civischile.infinitech.app/planificaciones/colegios', {
      user: rut
    },
    {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async get(planificacion: number) {
    return axios.get('http://api.civischile.infinitech.app/planificaciones/' + planificacion, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async save(planificacion: any) {
    if (planificacion.id === undefined) {
      return axios.post('http://api.civischile.infinitech.app/planificaciones', {
        planificacion
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
      })
      .then((res) => {
        const response = {
          data: res.data.data,
          message: undefined
        };
        return response;
      })
      .catch((err) => {
        const response = {
          data: err,
          message: 'Servicio no disponible'
        };
        return response;
      });
    } else {
      return axios.put('http://api.civischile.infinitech.app/planificaciones', {
        planificacion
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
      })
      .then((res) => {
        const response = {
          data: res.data.data,
          message: undefined
        };
        return response;
      })
      .catch((err) => {
        const response = {
          data: err,
          message: 'Servicio no disponible'
        };
        return response;
      });
    }
  }
}
