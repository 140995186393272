import { Injectable } from '@angular/core';
import axios from 'axios';

import { Item } from '../shared/item.class';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor() { }

  async all(planificacion: number) {
    return axios.get('http://api.civischile.infinitech.app/items/' + planificacion, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async save(item: Item) {
    if (item.id === undefined) {
      return axios.post('http://api.civischile.infinitech.app/planificaciones/items', {
        item
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
      })
      .then((res) => {
        const response = {
          data: res.data.data,
          message: undefined
        };
        return response;
      })
      .catch((err) => {
        const response = {
          data: err,
          message: 'Servicio no disponible'
        };
        return response;
      });
    } else {
      return axios.put('http://api.civischile.infinitech.app/planificaciones/items', {
        item
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        },
      })
      .then((res) => {
        const response = {
          data: res.data.data,
          message: undefined
        };
        return response;
      })
      .catch((err) => {
        const response = {
          data: err,
          message: 'Servicio no disponible'
        };
        return response;
      });
    }
  }
}
