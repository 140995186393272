import { Component, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  template: `
    <input type="checkbox" id="toggle-button-checkbox"
      (change)="changed.emit($event.target.checked)">
    <label class="toggle-button-switch"
      for="toggle-button-checkbox"></label>
    <div class="toggle-button-text">
      <div class="toggle-button-text-on">ON</div>
      <div class="toggle-button-text-off">OFF</div>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      position: relative;
      width: 70px;
      height: 50px;
    }

    input[type="checkbox"] {
      display: none;
    }

    /* 토글 버튼 내부의 원. 감춘 체크박스와 연동한다. */
    .toggle-button-switch {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 26px; /* Anchura del botón */
      height: 16px; /* Altura del botón */
      background-color: #eee; /* Color del botón */
      border-radius: 40%; /* Nivel de redondeo del botón */
      border: 1px solid #bbb;
      cursor: pointer;
      z-index: 100;
      transition: left 0.3s;
    }

    /* 토글 버튼의 바탕 */
    .toggle-button-text {
      overflow: hidden;
      background-color: #ccc; /* Color de fondo en mongo OFF */
      border-radius: 25px;
      box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
      transition: background-color 0.3s;
    }

    /* 토글 버튼의 텍스트 */
    .toggle-button-text-on,
    .toggle-button-text-off {
      float: left;
      width:50%;
      height: 100%;
      line-height: 20px; /* Altura del marco del botón */
      font-family: Lato, sans-serif;
      font-weight: bold;
      color: #fff;
      font-size: 10px;
      text-align: center;
    }

    /* 체크박스가 체크 상태이면 토글 버튼 내부의 원을 오른쪽으로 52px 이동 */
    input[type="checkbox"]:checked ~ .toggle-button-switch {
      left: 42px; /* Desplazamiento del botón */
    }

    /* 체크박스가 체크 상태이면 토글 버튼의 배경색 변경 */
    input[type="checkbox"]:checked ~ .toggle-button-text {
      background-color: #5896ff; /* Color de fondo en mongo ON */
    }
  `]
})
export class ToggleButtonComponent  {
  @Output() changed = new EventEmitter<boolean>();
}
