<div class="page-container">
    <div class="left-content">
        <div class="mother-grid-inner">
            <app-trace></app-trace>
            <div class="validation-system">
                <div class="validation-form" *ngIf="planificacion !== undefined">
                    <form>
                        <div class="vali-form">
                            <div class="col-md-2">
                                <!-- Validar el tipo para obtener la imagen a mostrar (this.state.planificacion.tipo) -->
                                <img src="assets/img/logo-civis-chile.png" width="90px" alt="Logo Civis Chile" /><br /><br />
                                <app-toggle-button *ngIf="showBtnEditar" (changed)="checked=$event; save()"></app-toggle-button>
                            </div>
                            <div class="col-md-10 planificacion-item">
                                <span *ngIf="!checked" class="planificacion planificacion-titulo">Asignatura:</span> <span *ngIf="!checked" class="planificacion planificacion-texto">{{ planificacion.asignatura.nombre }}</span>
                                <div *ngIf="checked" class="col-md-12 form-group2 group-mail">
                                    <label class="control-label">Asignatura</label>
                                    <select [(ngModel)]="asignatura" name="asignatura" (change)="loadCursos()">
                                      <option value='0'>--SELECCIONE--</option>
                                      <option *ngFor="let asignatura of asignaturasList" value="{{asignatura.id}}">{{ asignatura.nombre }}</option>
                                  </select>
                                </div>
                                <br /><br/>
                                <span *ngIf="!checked" class="planificacion planificacion-titulo">Curso:</span> <span *ngIf="!checked" class="planificacion planificacion-texto">{{ planificacion.curso.nombre }}</span>
                                <div *ngIf="checked" class="col-md-12 form-group2 group-mail">
                                    <label class="control-label">Curso</label>
                                    <select [(ngModel)]="curso" name="curso">
                                      <option value='0'>--SELECCIONE--</option>
                                      <option *ngFor="let curso of cursosList" value="{{curso.id}}">{{ curso.nombre }}</option>
                                  </select>
                                </div>
                                <br /><br />
                                <span *ngIf="!checked" class="rating">
                                  <input type="radio" name="rating" value="5" id="5"><label for="5">☆</label>
                                  <input type="radio" name="rating" value="4" id="4"><label for="4">☆</label>
                                  <input type="radio" name="rating" value="3" id="3"><label for="3">☆</label>
                                  <input type="radio" name="rating" value="2" id="2"><label for="2">☆</label>
                                  <input type="radio" name="rating" value="1" id="1"><label for="1">☆</label>
                                </span>
                                <span *ngIf="planificacion.destacada === 1 && !checked" class="badge badge-warning">DESTACADA</span>
                            </div>
                            <div *ngIf="!checked" class="col-md-12">
                                <div class="planificacion planificacion-nombre">{{ planificacion.nombre }}</div>
                            </div>
                            <div *ngIf="checked" class="col-md-12 form-group2 group-mail">
                                <label class="control-label input-padding">Nombre</label><br />
                                <input [(ngModel)]="nombre" name="nombre" type="email" class="form-control" />
                            </div>
                            <div *ngIf="!checked" class="col-md-12">
                                <div class="planificacion planificacion-titulo">Organismos</div>
                                <div class="planificacion planificacion-texto">
                                    <ol>
                                        <li *ngFor="let organismo of planificacion.organismos">{{ organismo.nombre }}</li>
                                    </ol>
                                </div>
                            </div>
                            <div *ngIf="checked" class="col-md-12 form-group2 group-mail">
                                <label class="control-label organismos-label-padding">Organismos</label><br />
                                <div *ngFor="let organismo of organismosList" class="checkbox-inline1">
                                    <label *ngIf="findOrganismo(organismo.id)"><input checked value="{{organismo.id}}" (change)="saveOrganismo(organismo.id)" type="checkbox" /> {{organismo.nombre}}</label>
                                    <label *ngIf="!findOrganismo(organismo.id)"><input value="{{organismo.id}}" (change)="saveOrganismo(organismo.id)" type="checkbox" /> {{organismo.nombre}}</label>
                                </div>
                            </div>
                            <!-- Listar los items y representar su contenido HTML -->
                            <div class="col-md-12" *ngFor="let item of planificacion.items">
                                <div class="planificacion planificacion-encabezado">{{ item.encabezado }}</div>
                                <!--<<div class="planificacion planificacion-contenido" [innerHTML]="test"></div>-->
                                <div class="planificacion planificacion-contenido" [innerHTML]="item.contenido"></div>
                                <br /><br />
                            </div>
                        </div>
                        <div *ngIf="showBtnEditar" class="col-md-12 form-group2 group-mail">
                            <label class="control-label input-padding">Encabezado</label><br />
                            <input [(ngModel)]="itemEncabezado" name="nombre" type="email" class="form-control" />
                            <label class="control-label input-padding">Contenido</label><br />
                            <textarea [(ngModel)]="itemContenido" name="nombre" type="email" class="form-control"></textarea>

                        </div>
                        <div *ngIf="showBtnEditar" class="col-md-12 form-group">
                            <button type="button" class="btn btn-primary" (click)="newItem()">Agregar Item</button>
                        </div>
                        <div *ngIf="showBtnAdaptar" class="col-md-12 form-group">
                            <button type="button" class="btn btn-primary">Adaptar</button>
                        </div>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
    <div class="sidebar-menu">
        <app-header></app-header>
        <div style="border-top: '1px ridge rgba(255, 255, 255, 0.15)'"></div>
        <app-menu></app-menu>
    </div>
    <div class="clearfix"></div>
</div>