<div class="wrapper fadeInDown">
    <div id="formContent">
        <div class="fadeIn first">
            <img src="../../assets/img/logo-civis-chile.png" id="icon" alt="Logo Civis Chile" />
        </div>
        <form>
            <input [(ngModel)]="user" name="user" type="text" class="fadeIn second" placeholder="Usuario" />
            <input [(ngModel)]="pass" name="pass" type="password" class="fadeIn third" placeholder="Clave" />
            <input type="button" class="fadeIn fourth" value="Ingresar" (click)="submit()" />
        </form>
        <div id="formFooter" class="loginError">{{ message }}</div>
    </div>
</div>