import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.css']
})
export class TraceComponent implements OnInit {

  public tracesList: any;

  constructor(
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.tracesList = this.sessionService.getTraces();
  }

}
