import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../../../services/session.service';

import { Trace } from '../../../../shared/trace.class';

@Component({
  selector: 'app-asignaturas-view',
  templateUrl: './asignaturas-view.component.html',
  styleUrls: ['./asignaturas-view.component.css']
})
export class AsignaturasViewComponent implements OnInit {

  public tracesList: Array<Trace>;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.tracesList = new Array();
    this.tracesList.push({link: '/asignaturas', text: 'Asignaturas'});
    this.tracesList.push({link: '', text: 'Ver'});
    this.sessionService.setTraces(this.tracesList);
  }

}
