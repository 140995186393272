import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
// import { DomSanitizer } from '@angular/platform-browser';

import { SessionService } from '../../../../services/session.service';
import { AsignaturasService } from '../../../../services/asignaturas.service';
import { DropdownService } from '../../../../services/dropdown.service';
import { OrganismosService } from '../../../../services/organismos.service';
import { PlanificacionesService } from '../../../../services/planificaciones.service';
import { ItemsService } from '../../../../services/items.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { Trace } from '../../../../shared/trace.class';
import { Planificacion } from '../../../../shared/planificacion.class';
import { Colegio } from '../../../../shared/colegio.class';
import { Asignatura } from '../../../../shared/asignatura.class';
import { Curso } from '../../../../shared/curso.class';
import { Organismo } from '../../../../shared/organismo.class';
import { Item } from '../../../../shared/item.class';

@Component({
  selector: 'app-planificaciones-view',
  templateUrl: './planificaciones-view.component.html',
  styleUrls: ['./planificaciones-view.component.css']
})
export class PlanificacionesViewComponent implements OnInit {

  public tracesList: Array<Trace>;
  public planificacion: Planificacion;
  // public test: any;
  public id: any;
  public showBtnEditar = false;
  public showBtnAdaptar = false;
  public checked: boolean;
  public asignaturasList: [];
  public cursosList: [];
  public organismosList: [];
  public colegio: undefined;
  public nombre: string;
  public asignatura: number;
  public curso: number;
  public organismos = new Array<number>();
  public spinnerText: string;
  public itemEncabezado: string;
  public itemContenido: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    // private sanitizer: DomSanitizer,
    private sessionService: SessionService,
    private asignaturasService: AsignaturasService,
    private dropdownService: DropdownService,
    private organismosService: OrganismosService,
    private planificacionesService: PlanificacionesService,
    private itemsService: ItemsService
  ) {
  }

  ngOnInit(): void {
    this.spinnerText = 'Procesando...';
    this.init();
    this.tracesList = new Array();
    this.tracesList.push({link: '/planificaciones', text: 'Planificaciones'});
    this.tracesList.push({link: '', text: 'Ver'});
    this.sessionService.setTraces(this.tracesList);
    // this.test = this.sanitizer.bypassSecurityTrustHtml('<span class="planificacion planificacion-titulo">hello world</span>');
  }

  async init() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));

    if ( !isNaN(this.id) ) {
      await this.loadPlanificacion(this.id);

      if (this.sessionService.getRole() === 1 && this.planificacion.tipo === 1) {
        this.showBtnEditar = true;
      }

      if (
        Number(this.sessionService.getRole()) === 3 && (Number(this.planificacion.tipo) === 2 || Number(this.planificacion.tipo) === 3)
      ) {
        if (this.sessionService.getPayload().ucolegios[0].id === this.planificacion.colegio.id) {
          this.showBtnEditar = true;
        }
      }

      if (this.sessionService.getRole() === 3 && this.planificacion.tipo === 1) {
        this.showBtnAdaptar = true;
      }

      this.loadAsignaturas();
      this.loadOrganismos();
    } else {
      this.router.navigate([`/oops`]);
    }
  }

  async loadPlanificacion(planificacion: number) {
    const response = await this.planificacionesService.get(planificacion);
    this.planificacion = response.data;
    this.nombre = this.planificacion.nombre;
    this.asignatura = this.planificacion.asignatura.id;
    this.curso = this.planificacion.curso.id;
    this.loadCursos();
    this.organismos = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.planificacion.organismos.length; i++) {
      this.organismos.push(this.planificacion.organismos[i].id);
    }

    console.log(this.sessionService.getPayload());
    console.log(this.planificacion);
  }

  async loadAsignaturas() {
    const response = await this.asignaturasService.all();
    this.asignaturasList = response.data;
  }

  async loadCursos() {
    this.spinner.show();
    const response = await this.dropdownService.cursos(this.asignatura);
    this.spinner.hide();
    this.cursosList = response.data;
  }

  async loadOrganismos() {
    const response = await this.organismosService.all();
    this.organismosList = response.data;
  }

  saveOrganismo(id: number) {
    if ( this.organismos.find(element => element === id) === undefined ) {
      this.organismos.push(id);
    } else {
      this.organismos = this.organismos.filter(element => element !== id);
    }
  }

  findOrganismo(id: any) {
    if ( this.organismos.find(element => element === id) === undefined ) {
      return false;
    } else {
      return true;
    }
  }

  async save() {
    if (!this.checked) {
      if (this.sessionService.getPayload().urol === 3) {
        // TODO: desarrollar lógica para determinar el colegio con usuarios con role 2.
        this.colegio = this.sessionService.getPayload().ucolegios[0].id;
      }

      if (this.nombre !== undefined && this.nombre !== '' && this.asignatura > 0 && this.curso > 0) {
        const planificacion = new Planificacion();
        planificacion.id = this.id;
        planificacion.nombre = this.nombre;
        planificacion.tipo = this.sessionService.getPayload().urol;
        const colegio = new Colegio();
        colegio.id = this.colegio;
        planificacion.colegio = colegio;
        const asignatura = new Asignatura();
        asignatura.id = this.asignatura;
        planificacion.asignatura = asignatura;
        const curso = new Curso();
        curso.id = this.curso;
        planificacion.curso = curso;
        const organismos = Array<Organismo>();

        this.organismos.forEach(element => {
          const organismo = new Organismo();
          organismo.id = element;
          organismos.push(organismo);
        });

        planificacion.organismos = organismos;

        this.spinner.show();
        const response = await this.planificacionesService.save(planificacion);
        this.init();
        // this.checked = false;
        this.spinner.hide();
        // this.router.navigate([`/planificaciones/${this.id}`]);
      }
    }
  }

  async newItem() {
    if (this.itemEncabezado !== '') {
      const item = new Item();
      item.encabezado = this.itemEncabezado;
      item.contenido = this.itemContenido;
      const planificacion = new Planificacion();
      planificacion.id = this.id;
      item.planificacion = planificacion;

      this.spinner.show();
      await this.itemsService.save(item);
      this.init();
      this.spinner.hide();
    }
  }
}
