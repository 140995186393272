import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { InicioComponent } from './components/pages/inicio/inicio.component';
import { LoginComponent } from './components/pages/login/login.component';
import { UsuariosSaveComponent } from './components/pages/usuarios/usuarios-save/usuarios-save.component';
import { UsuariosViewComponent } from './components/pages/usuarios/usuarios-view/usuarios-view.component';
import { UsuariosListComponent } from './components/pages/usuarios/usuarios-list/usuarios-list.component';
import { AsignaturasSaveComponent } from './components/pages/asignaturas/asignaturas-save/asignaturas-save.component';
import { AsignaturasViewComponent } from './components/pages/asignaturas/asignaturas-view/asignaturas-view.component';
import { AsignaturasListComponent } from './components/pages/asignaturas/asignaturas-list/asignaturas-list.component';
import { OrganismosSaveComponent } from './components/pages/organismos/organismos-save/organismos-save.component';
import { OrganismosViewComponent } from './components/pages/organismos/organismos-view/organismos-view.component';
import { OrganismosListComponent } from './components/pages/organismos/organismos-list/organismos-list.component';
import { ColegiosSaveComponent } from './components/pages/colegios/colegios-save/colegios-save.component';
import { ColegiosViewComponent } from './components/pages/colegios/colegios-view/colegios-view.component';
import { ColegiosListComponent } from './components/pages/colegios/colegios-list/colegios-list.component';
import { PlanificacionesSaveComponent } from './components/pages/planificaciones/planificaciones-save/planificaciones-save.component';
import { PlanificacionesListComponent } from './components/pages/planificaciones/planificaciones-list/planificaciones-list.component';
import { PlanificacionesViewComponent } from './components/pages/planificaciones/planificaciones-view/planificaciones-view.component';

const routes: Routes = [
  { path: 'oops', component: NotFoundComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'login', component: LoginComponent },
  { path: 'usuarios/crear', component: UsuariosSaveComponent },
  { path: 'usuarios', component: UsuariosListComponent },
  { path: 'usuarios/:id', component: UsuariosViewComponent },
  { path: 'asignaturas/crear', component: AsignaturasSaveComponent },
  { path: 'asignaturas', component: AsignaturasListComponent },
  { path: 'asignaturas/:id', component: AsignaturasViewComponent },
  { path: 'organismos/crear', component: OrganismosSaveComponent },
  { path: 'organismos', component: OrganismosListComponent },
  { path: 'organismos/:id', component: OrganismosViewComponent },
  { path: 'colegios/crear', component: ColegiosSaveComponent },
  { path: 'colegios', component: ColegiosListComponent },
  { path: 'colegios/:id', component: ColegiosViewComponent },
  { path: 'planificaciones/crear', component: PlanificacionesSaveComponent },
  { path: 'planificaciones', component: PlanificacionesListComponent },
  { path: 'planificaciones/:id', component: PlanificacionesViewComponent },
  { path: '',   redirectTo: 'inicio', pathMatch: 'full' },
  { path: '**', redirectTo: 'oops' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
