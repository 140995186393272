import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { InicioComponent } from './components/pages/inicio/inicio.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MenuComponent } from './components/commons/menu/menu.component';
import { HeaderComponent } from './components/commons/header/header.component';
import { FooterComponent } from './components/commons/footer/footer.component';
import { TraceComponent } from './components/commons/trace/trace.component';
import { PlanificacionesViewComponent } from './components/pages/planificaciones/planificaciones-view/planificaciones-view.component';
import { PlanificacionesListComponent } from './components/pages/planificaciones/planificaciones-list/planificaciones-list.component';
import { PlanificacionesSaveComponent } from './components/pages/planificaciones/planificaciones-save/planificaciones-save.component';
import { ColegiosSaveComponent } from './components/pages/colegios/colegios-save/colegios-save.component';
import { ColegiosViewComponent } from './components/pages/colegios/colegios-view/colegios-view.component';
import { ColegiosListComponent } from './components/pages/colegios/colegios-list/colegios-list.component';
import { UsuariosSaveComponent } from './components/pages/usuarios/usuarios-save/usuarios-save.component';
import { UsuariosViewComponent } from './components/pages/usuarios/usuarios-view/usuarios-view.component';
import { UsuariosListComponent } from './components/pages/usuarios/usuarios-list/usuarios-list.component';
import { AsignaturasSaveComponent } from './components/pages/asignaturas/asignaturas-save/asignaturas-save.component';
import { AsignaturasViewComponent } from './components/pages/asignaturas/asignaturas-view/asignaturas-view.component';
import { AsignaturasListComponent } from './components/pages/asignaturas/asignaturas-list/asignaturas-list.component';
import { OrganismosSaveComponent } from './components/pages/organismos/organismos-save/organismos-save.component';
import { OrganismosViewComponent } from './components/pages/organismos/organismos-view/organismos-view.component';
import { OrganismosListComponent } from './components/pages/organismos/organismos-list/organismos-list.component';

import { SessionService } from './services/session.service';
import { AuthenticationService } from './services/authentication.service';
import { ToggleButtonComponent } from './components/commons/toggle-button/toggle-button.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    InicioComponent,
    LoginComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    TraceComponent,
    PlanificacionesViewComponent,
    PlanificacionesListComponent,
    PlanificacionesSaveComponent,
    ColegiosSaveComponent,
    ColegiosViewComponent,
    ColegiosListComponent,
    UsuariosSaveComponent,
    UsuariosViewComponent,
    UsuariosListComponent,
    AsignaturasSaveComponent,
    AsignaturasViewComponent,
    AsignaturasListComponent,
    OrganismosSaveComponent,
    OrganismosViewComponent,
    OrganismosListComponent,
    ToggleButtonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    SessionService,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
