import { Asignatura } from './asignatura.class';
import { Curso } from './curso.class';
import { Organismo } from './organismo.class';
import { Item } from './item.class';
import { Colegio } from './colegio.class';
import { Recurso } from './recurso.class';

export class Planificacion {
  id: number;
  nombre: string;
  colegio: Colegio;
  asignatura: Asignatura;
  curso: Curso;
  tipo: number;
  destacada: number;
  organismos: Array<Organismo>;
  items: Array<Item>;
  recursos: Array<Recurso>;
  valoraciones: number;
  ejecutada: number;
  activo: number;
}
