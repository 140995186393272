<div class="page-container">
    <div class="left-content">
        <div class="mother-grid-inner">
            <app-trace></app-trace>
            <div class="validation-system">
                <div class="validation-form" *ngIf="planificaciones !== undefined">
                    <div class="clearfix">
                        <table class="table table-hover">
                            <caption>PLANIFICACIONES CIVIS</caption>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Asignatura</th>
                                    <th scope="col">Curso</th>
                                    <th scope="col"></th>
                                    <th scope="col">Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let planificacion of planificaciones; let i = index">
                                    <tr *ngIf="planificacion.destacada === 1" class="planificacion-link" [routerLink]="['/planificaciones', planificacion.id]">
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ planificacion.asignatura.nombre }}</td>
                                        <td>{{ planificacion.curso.nombre }}</td>
                                        <td><span class="badge badge-warning">DESTACADA</span></td>
                                        <td>{{ planificacion.nombre }}</td>
                                    </tr>
                                    <tr *ngIf="planificacion.destacada === 0" class="planificacion-link" [routerLink]="['/planificaciones', planificacion.id]">
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ planificacion.asignatura.nombre }}</td>
                                        <td>{{ planificacion.curso.nombre }}</td>
                                        <td></td>
                                        <td>{{ planificacion.nombre }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
    <div class="sidebar-menu">
        <app-header></app-header>
        <div style="border-top: '1px ridge rgba(255, 255, 255, 0.15)'"></div>
        <app-menu></app-menu>
    </div>
    <div class="clearfix"></div>
</div>