<div class="menu">
    <ul id="menu">
        <li><a routerLink="/inicio"><i class="fa fa-home"></i> <span>Mis Planificaciones</span><div class="clearfix"></div></a></li>
        <li *ngIf="sessionService.isAdmin()"><a routerLink="/usuarios"><i class="fas fa-user"></i> <span>Usuarios</span><div class="clearfix"></div></a></li>
        <li><a routerLink="/asignaturas"><i class="fas fa-book-open"></i> <span>Asignaturas</span><div class="clearfix"></div></a></li>
        <li><a routerLink="/organismos"><i class="fas fa-user-graduate"></i> <span>Organismos Estudiantiles</span><div class="clearfix"></div></a></li>
        <li *ngIf="sessionService.isAdmin()"><a routerLink="/colegios"><i class="fas fa-school"></i> <span>Colegios</span><div class="clearfix"></div></a></li>
        <li id="menu-planificaciones">
            <span class="menu-con-opciones"><i class="fa fa-tasks" aria-hidden="true"></i> <span>Planificaciones</span> <span class="fa fa-angle-right" style="float: 'right'"></span>
            <div class="clearfix"></div>
            </span>
            <ul id="menu-planificaciones-sub">
                <li id="menu-planificaciones-red-civis"><a routerLink="/planificaciones">Red Civis</a></li>
                <li id="menu-planificaciones-otros-colegios"><a routerLink="/planificaciones">Otros Colegios</a></li>
                <li id="menu-planificaciones-crear"><a routerLink="/planificaciones/crear">Crear</a></li>
            </ul>
        </li>
        <li><a routerLink="/login" (click)="logout()"><i class="fas fa-power-off"></i> Salir</a> </li>
    </ul>
</div>