<div class="page-container">
    <div class="left-content">
        <div class="mother-grid-inner">
            <app-trace></app-trace>
            <div class="validation-system">
                <form>
                    <div class="validation-form">
                        <div class="clearfix">
                            <div class="col-md-12 form-group2 group-mail">
                                <label class="control-label input-padding">Nombre</label><br />
                                <input [(ngModel)]="nombre" name="nombre" type="email" class="form-control" />
                            </div>
                            <div class="col-md-12 form-group2 group-mail">
                                <label class="control-label">Asignatura</label>
                                <select [(ngModel)]="asignatura" name="asignatura" (change)="loadCursos()">
                                    <option value='0'>--SELECCIONE--</option>
                                    <option *ngFor="let asignatura of asignaturasList" value="{{asignatura.id}}">{{ asignatura.nombre }}</option>
                                </select>
                            </div>
                            <div class="col-md-12 form-group2 group-mail">
                                <label class="control-label">Curso</label>
                                <select [(ngModel)]="curso" name="curso">
                                    <option value='0'>--SELECCIONE--</option>
                                    <option *ngFor="let curso of cursosList" value="{{curso.id}}">{{ curso.nombre }}</option>
                                </select>
                            </div>
                            <div class="col-md-12 form-group2 group-mail">
                                <label class="control-label organismos-label-padding">Ministerios Escolares involucrados</label><br />
                                <div *ngFor="let organismo of organismosList" class="checkbox-inline1"><label><input value="{{organismo.id}}" (change)="saveOrganismo(organismo.id)" type="checkbox" /> {{organismo.nombre}}</label></div>
                            </div>
                            <div class="col-md-12 form-group text-center">
                                <button type="button" class="btn btn-primary" (click)="save()">Crear</button>
                                <button type="reset" class="btn btn-secondary">Reset</button>
                            </div>
                        </div>
                        <app-footer></app-footer>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="sidebar-menu">
        <app-header></app-header>
        <div style="border-top: '1px ridge rgba(255, 255, 255, 0.15)'"></div>
        <app-menu></app-menu>
    </div>
    <div class="clearfix"></div>
</div>

<ngx-spinner bdColor="#aaa" size="medium" color="#000" type="ball-scale-multiple">
    <p style="font-size: 20px; color:black;">{{ spinnerText }}</p>
</ngx-spinner>