import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  async signIn(user: string, pass: string) {
    return await axios.post('http://api.civischile.infinitech.app/authentication', {
        user,
        pass
      })
      .then((res) => {
        // TODO: revisar por qué los status diferentes de 200 los considera como un error y lanza excepción.
        const auth = {
          data: undefined,
          message: undefined
        };
        if (res.status === 200) {
          auth.data = res.data.data;
        } else if (res.status === 422) {
          auth.message = 'Usuario o Clave incorrectos';
        } else if (res.status === 404) {
          auth.message = 'Error en la llamada al servicio.';
        } else {
          auth.message = 'Servicio no disponible.';
        }
        return auth;
      })
      .catch((err) => {
        const auth = {
          data: err,
          message: 'Usuario o Clave incorrectos'
        };
        return auth;
      });
  }
}
