import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import JWT from 'jsonwebtoken';
import { Base64 } from 'js-base64';

import { SessionService } from '../../../services/session.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public user: string;
  public pass: string;
  public message: string;

  constructor(
    private router: Router,
    public sessionService: SessionService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.validate();
  }

  validate() {
    if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('sessionId') !== null) {
      const token = sessionStorage.getItem('token');
      const sessionId = sessionStorage.getItem('sessionId').substring(1);
      const publicKey = Base64.decode(sessionId);

      JWT.verify(token, publicKey, async (err: any, decode: any) => {
          if (err) {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('sessionId');
            sessionStorage.clear();
          } else {
              this.goHome();
          }
      });
    } else {
      sessionStorage.clear();
    }
  }

  async submit() {
    const auth = await this.authService.signIn(this.user, this.pass);
    if (auth.message === undefined) {
      sessionStorage.setItem('token', auth.data.token);
      sessionStorage.setItem('sessionId', auth.data.sessionId);
      this.sessionService.init();
      this.goHome();
    }
    this.message = auth.message;
  }

  goHome() {
    this.router.navigate([`/inicio`]);
  }

}
