import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SessionService } from '../../../../services/session.service';
import { AsignaturasService } from '../../../../services/asignaturas.service';
import { DropdownService } from '../../../../services/dropdown.service';
import { OrganismosService } from '../../../../services/organismos.service';
import { PlanificacionesService } from '../../../../services/planificaciones.service';

import { ThrowStmt } from '@angular/compiler';
import { Trace } from '../../../../shared/trace.class';
import { Payload } from '../../../../shared/payload.class';
import { Planificacion } from '../../../../shared/planificacion.class';
import { Colegio } from '../../../../shared/colegio.class';
import { Asignatura } from '../../../../shared/asignatura.class';
import { Curso } from '../../../../shared/curso.class';
import { Organismo } from '../../../../shared/organismo.class';

@Component({
  selector: 'app-planificaciones-save',
  templateUrl: './planificaciones-save.component.html',
  styleUrls: ['./planificaciones-save.component.css']
})
export class PlanificacionesSaveComponent implements OnInit {

  public tracesList: Array<Trace>;
  public asignaturasList: [];
  public cursosList: [];
  public organismosList: [];
  public colegio: undefined;
  public nombre: string;
  public asignatura: number;
  public curso: number;
  public organismos: Array<number>;
  public spinnerText: string;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private sessionService: SessionService,
    private asignaturasService: AsignaturasService,
    private dropdownService: DropdownService,
    private organismosService: OrganismosService,
    private planificacionesService: PlanificacionesService
  ) { }

  ngOnInit(): void {
    this.spinnerText = 'Procesando...';
    this.tracesList = new Array();
    this.tracesList.push({link: '/planificaciones', text: 'Planificaciones'});
    this.tracesList.push({link: '', text: 'Crear'});
    this.sessionService.setTraces(this.tracesList);
    this.asignatura = 0;
    this.curso = 0;
    this.organismos = new Array();
    this.loadAsignaturas();
    this.loadOrganismos();
  }

  async loadAsignaturas() {
    const response = await this.asignaturasService.all();
    this.asignaturasList = response.data;
  }

  async loadCursos() {
    this.spinner.show();
    const response = await this.dropdownService.cursos(this.asignatura);
    this.spinner.hide();
    this.cursosList = response.data;
  }

  async loadOrganismos() {
    const response = await this.organismosService.all();
    this.organismosList = response.data;
  }

  saveOrganismo(id: number) {
    if ( this.organismos.find(element => element === id) === undefined ) {
      this.organismos.push(id);
    } else {
      this.organismos = this.organismos.filter(element => element !== id);
    }
  }

  async save() {
    if (this.sessionService.getPayload().urol === 3) {
      // TODO: desarrollar lógica para determinar el colegio con usuarios con role 2.
      this.colegio = this.sessionService.getPayload().ucolegios[0].id;
    }

    if (this.nombre !== undefined && this.nombre !== '' && this.asignatura > 0 && this.curso > 0) {
      const planificacion = new Planificacion();
      planificacion.nombre = this.nombre;
      planificacion.tipo = this.sessionService.getPayload().urol;
      const colegio = new Colegio();
      colegio.id = this.colegio;
      planificacion.colegio = colegio;
      const asignatura = new Asignatura();
      asignatura.id = this.asignatura;
      planificacion.asignatura = asignatura;
      const curso = new Curso();
      curso.id = this.curso;
      planificacion.curso = curso;
      const organismos = Array<Organismo>();

      this.organismos.forEach(element => {
        const organismo = new Organismo();
        organismo.id = element;
        organismos.push(organismo);
      });

      planificacion.organismos = organismos;

      this.spinner.show();
      const response = await this.planificacionesService.save(planificacion);
      this.spinner.hide();
      this.router.navigate([`/planificaciones/${response.data}`]);
    }
  }
}
