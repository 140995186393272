import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../../services/session.service';
import { PlanificacionesService } from '../../../services/planificaciones.service';
import { Trace } from '../../../shared/trace.class';
import { Planificacion } from 'src/app/shared/planificacion.class';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public tracesList: Array<Trace>;
  public planificaciones: Array<Planificacion>;

  constructor(
    private sessionService: SessionService,
    private planificacionesService: PlanificacionesService
  ) { }

  ngOnInit(): void {
    console.log(this.sessionService.getPayload());
    // this.tracesList = new Array();
    this.sessionService.setTraces(new Array<any>());
    this.loadPlanificaciones();
  }

  async loadPlanificaciones() {
    console.log(this.sessionService.getPayload());
    let response: any;

    switch (this.sessionService.getRole()) {
      case 1:
        response = await this.planificacionesService.civis();
        this.planificaciones = response.data;
        break;

      case 2:
        response = await this.planificacionesService.colegios(this.sessionService.getPayload().uid);
        this.planificaciones = response.data;
        break;

      case 3:
        response = await this.planificacionesService.colegio(this.sessionService.getPayload().ucolegios[0].id);
        this.planificaciones = response.data;
        break;
    }
  }

}
