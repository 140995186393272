import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import JWT from 'jsonwebtoken';
import { Base64 } from 'js-base64';

import { Payload } from '../shared/payload.class';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private payload: Payload;
  private traces: Array<any>;

  constructor(
    private router: Router
  ) { }

  async init() {
    if (this.payload === undefined) {
      if (sessionStorage.getItem('token') && sessionStorage.getItem('sessionId')) {
        const token = sessionStorage.getItem('token');
        const sessionId = sessionStorage.getItem('sessionId').substring(1);
        const publicKey = Base64.decode(sessionId);
        await JWT.verify(token, publicKey, (err: any, decode: any) => {
          if (err) {
            this.close();
          } else {
            this.payload = decode;
          }
        });
      } else {
        this.close();
      }
    }
  }

  isAdmin() {
    if (this.payload.urol === 1) {
      return true;
    } else {
      return false;
    }
  }

  getPayload() {
    return this.payload;
  }

  getRole() {
    if (this.payload !== undefined) {
      return this.payload.urol;
    } else {
      return undefined;
    }
  }

  getTraces() {
    return this.traces;
  }

  setTraces(traces: Array<any>) {
    this.traces = traces;
  }

  close() {
    this.payload = undefined;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('sessionId');
    sessionStorage.clear();
    this.router.navigate([`/login`]);
  }
}
