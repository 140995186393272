import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../../../services/session.service';

import { Trace } from '../../../../shared/trace.class';

@Component({
  selector: 'app-organismos-save',
  templateUrl: './organismos-save.component.html',
  styleUrls: ['./organismos-save.component.css']
})
export class OrganismosSaveComponent implements OnInit {

  public tracesList: Array<Trace>;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.tracesList = new Array();
    this.tracesList.push({link: '/organismos', text: 'Organismos'});
    this.tracesList.push({link: '', text: 'Crear'});
    this.sessionService.setTraces(this.tracesList);
  }

}
