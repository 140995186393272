<div class="page-container">
    <div class="left-content">
        <div class="mother-grid-inner">
            <app-trace></app-trace>
            <div class="validation-system">
                <div class="validation-form">
                    <div class="clearfix">
                        colegios-view
                    </div>
                    <div class="inner-block"></div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-menu">
        <app-header></app-header>
        <div style="border-top: '1px ridge rgba(255, 255, 255, 0.15)'"></div>
        <app-menu></app-menu>
    </div>
    <div class="clearfix"></div>
</div>