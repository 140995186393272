import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class OrganismosService {

  constructor() { }

  async all() {
    return axios.get('http://api.civischile.infinitech.app/organismos', {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }
}
