import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../../../services/session.service';

import { Trace } from '../../../../shared/trace.class';

@Component({
  selector: 'app-colegios-save',
  templateUrl: './colegios-save.component.html',
  styleUrls: ['./colegios-save.component.css']
})
export class ColegiosSaveComponent implements OnInit {

  public tracesList: Array<Trace>;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.tracesList = new Array();
    this.tracesList.push({link: '/colegios', text: 'Colegios'});
    this.tracesList.push({link: '', text: 'Crear'});
    this.sessionService.setTraces(this.tracesList);
  }

}
