import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor() { }

  async roles() {
    return axios.get('http://api.civischile.infinitech.app/dropdown/roles', {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async regiones() {
    return axios.get('http://api.civischile.infinitech.app/dropdown/regiones', {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async provincias(region: number) {
    return axios.get('http://api.civischile.infinitech.app/dropdown/provincias/' + region, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async comunas(provincia: number) {
    return axios.get('http://api.civischile.infinitech.app/dropdown/comunas/' + provincia, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }

  async cursos(asignatura: number) {
    return axios.get('http://api.civischile.infinitech.app/dropdown/cursos/' + asignatura, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    })
    .then((res) => {
      const response = {
        data: res.data.data,
        message: undefined
      };
      return response;
    })
    .catch((err) => {
      const response = {
        data: err,
        message: 'Servicio no disponible'
      };
      return response;
    });
  }
}
