import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../../../services/session.service';

import { Trace } from '../../../../shared/trace.class';

@Component({
  selector: 'app-colegios-list',
  templateUrl: './colegios-list.component.html',
  styleUrls: ['./colegios-list.component.css']
})
export class ColegiosListComponent implements OnInit {

  public tracesList: Array<Trace>;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.tracesList = new Array();
    this.tracesList.push({link: '', text: 'Colegios'});
    this.sessionService.setTraces(this.tracesList);
  }

}
