import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(
    private router: Router,
    public sessionService: SessionService
  ) {
    this.sessionService.init();
  }

  ngOnInit(): void {}

  logout() {
    this.sessionService.close();
  }

}
