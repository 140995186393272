import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

import { SessionService } from '../../../../services/session.service';
import { PlanificacionesService } from '../../../../services/planificaciones.service';

import { Trace } from '../../../../shared/trace.class';
import { Planificacion } from '../../../../shared/planificacion.class';

@Component({
  selector: 'app-planificaciones-list',
  templateUrl: './planificaciones-list.component.html',
  styleUrls: ['./planificaciones-list.component.css']
})
export class PlanificacionesListComponent implements OnInit {

  public tracesList: Array<Trace>;
  public planificaciones: Array<Planificacion>;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private planificacionesService: PlanificacionesService
  ) { }

  ngOnInit(): void {
    this.tracesList = new Array();
    this.tracesList.push({link: '', text: 'Planificaciones'});
    this.sessionService.setTraces(this.tracesList);
    this.loadPlanificaciones();
  }

  async loadPlanificaciones() {
    const response = await this.planificacionesService.civis();
    this.planificaciones = response.data;
  }
}
